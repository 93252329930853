<template>  
</template>

<script>
export default {
  name: 'reload',
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$router.replace(from.path)
    })
  }
}
</script>